import "./Header.css";

function Header() {
	return (
		<header className="header">
			<img src="/img/header.png" alt="org-header" />
		</header>
	);
}

export default Header;
